:root {
    --menu-logo-height: 67px;
    --menu-logo-width: 165px;
    --menu-height: 67px;
    --menu-position-top: 20px;
    --menu-position-left: 20px;
    --menu-logo-offset: -82px;
    --menu-burger-offset: -33px;
    --menu-logo-and-burger-offset-compensator: 0 33px 0 82px;
    --menu-lang-padding: 20px;
    --menu-items-as-icons-display: none;
    --menu-items-as-texts-display: inline;
    --menu-item-padding: 0 20px;
    --menu-items-separator-border: 2px solid #{$color-menu-top-row-separator};
    --menu-reviews-icon-padding: 0 25px;
    --menu-slogan-dektop: inline;
    --menu-slogan-mobile: none;

    @media (max-width: 680px) {
        --menu-logo-height: 50px;
        --menu-logo-width: 123px;
        --menu-height: 50px;
        --menu-position-top: 10px;
        --menu-position-left: 10px;
        --menu-logo-offset: -62px;
        --menu-burger-offset: -25px;
        --menu-logo-and-burger-offset-compensator: 0 25px 0 62px;
        --menu-lang-padding: 13px;
        --menu-items-as-icons-display: block;
        --menu-items-as-texts-display: none;
        --menu-item-padding: 0 10px;
        --menu-items-separator-border: none;
        --menu-reviews-icon-padding: 0 15px;
        --menu-slogan-dektop: none;
        --menu-slogan-mobile: inline;
    }

    --menu-icon-size: 20px;
}

.menu {
	position: absolute;
	top: var(--menu-position-top);
	left: var(--menu-position-left);
	z-index: 101;
    max-width: 650px;
    color: $color-menu-text;
    margin-right: 10px;

    a {
        &:focus {
            outline: none;
        }

        color: $color-menu-text;
        text-decoration: none;
    }

    &-inner {
        padding: var(--menu-logo-and-burger-offset-compensator);
    }

    &-row {
        display: flex;
        align-items: center;

        &--top {
            height: var(--menu-height);
            background-color: $color-menu-top-row-left;
            justify-content: space-between;
        }

        &--bottom {
            min-height: 40px;
            background-color: $color-menu-bottom-row-left;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            font-size: $font-size--smaler;
        }
    }

    &.turas .menu-row--bottom {
        display: flex;
    }

    &-logo {
        display: flex;
        margin-left: var(--menu-logo-offset);

        img {
            width: var(--menu-logo-width);
            height: var(--menu-logo-height);
        }
    }

    &-item {
        padding: var(--menu-item-padding);
        white-space: nowrap;

        &__email {
            border-left: var(--menu-items-separator-border);
        }

        &__fullscreen,
        &__gyro {
            display: var(--menu-items-as-icons-display);
        }

        &__fullscreen {
            padding: 0 5px 0 10px;
        }

        &__gyro {
            padding: 0 10px 0 5px;
        }

        img {
            height: var(--menu-icon-size);
        }

        &__phone,
        &__email {
            display: var(--menu-items-as-texts-display);
        }
    }

    &-space-eater {
        flex-grow: 1;
    }

    &-right {
        display: flex;
        background-color: $color-menu-top-row-right;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;

        &__languages {
            position: relative;
            width: var(--menu-height);
            height: var(--menu-height);
        }

        &__languages-list {
            position: absolute;
            top: 0;
            left: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            width: var(--menu-height);
            min-height: var(--menu-height);
            background-color: $color-menu-top-row-right;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;

            li {
                display: block;

                a {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: var(--menu-lang-padding) 0 0 0;
                }

                &:last-child a {
                    padding-bottom: var(--menu-lang-padding);
                }

                &.hidden {
                    display: none;
                }
            }

            &.open {
                li.hidden {
                    display: block;
                }
            }
        }

        &__burger {
            display: flex;
            margin-right: var(--menu-burger-offset);

            svg {
                width: var(--menu-height);
                height: var(--menu-height);

                .circle-background {
                    fill: $color-menu-burger-background;
                }

                .stripes-color {
                    fill: $color-menu-burger-stripes;
                }
            }
        }
    }

    &-title {
        flex-grow: 1;
        padding: 12px 16px 12px 25px;

        .desktop {
            display: var(--menu-slogan-dektop);
        }

        .mobile {
            display: var(--menu-slogan-mobile);
        }
    }

    &-reviews {
        display: flex;
        flex-shrink: 1;
        background-color: $color-menu-bottom-row-right;
        border-bottom-right-radius: 20px;
        padding: var(--menu-reviews-icon-padding);
        align-self: stretch;
        align-items: center;

        img {
            height: var(--menu-icon-size);
            display: var(--menu-items-as-icons-display);
        }

        span {
            display: var(--menu-items-as-texts-display);
        }
    }
}

.cookies + .menu {
    top: 70px;
}

@media (max-width: 460px) {
	.cookies + .menu {
        top: 90px;
    }
}

.cookies {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	min-height: 50px;
	background-color: #2a2a2a;
	z-index: 104;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		text-align: center;
  		padding: 8px 0;
	}

	a {
		margin: 0 12px;

		img {
			display: block;
			width: 19px;
			height: 19px;
		}
	}
}

@media (max-width: 460px) {
	.cookies span {
		max-width: 320px;
	}
}