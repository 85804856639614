@import 'variables';

@-ms-viewport { width:device-width; }

html {
    height:100%;
}

body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: $font-base;
	font-size: $font-size--base;
	color: #FFFFFF;
	position: relative;
}

@import 'menu';
@import 'footer';
@import 'textbox';
