#footer {
	position: absolute;
	bottom: 0px;
	width: 100%;
	min-height: 140px;
	z-index: 102;
	background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);

	.buttons {
		position: absolute;
		bottom: 80px;
		width: 100%;
		display: flex;
		justify-content: center;

		a {
			display: block;
			color: #FFF;
			text-decoration: none;
			padding: 20px 30px;
			background-color: rgba(0, 154, 213, .85);
			//#009ad5;

			&:first-child,
			&:last-child {
				background-color: rgba(0, 173, 239, .85);
				//#00adef
			}

			&:first-child {
				border-top-left-radius: 30px;
				border-bottom-left-radius: 30px;
			}

			&:last-child {
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
			}

			&:hover {
				background-color: rgba(237, 29, 36, .85);
				//#ed1d24
			}
		}
	}

	.indicators {
		position: absolute;
		bottom: 20px;
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;

		li {
			margin: 0 10px;
			padding: 0;
		}

		a {
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #3d3d3d;

			&.active {
				background-color: #00adef;
			}

			&:hover {
				background-color: #ed1d24;
			}
		}
	}

	.social {
		position: absolute;
		bottom: 20px;
		right: 120px;
		width: 200px;
		display: flex;

		a {
			margin-left: 10px;
			display: block;
			width: 40px;
			height: 40px;
		}

		svg {
			width: 40px;
			height: 40px;
		}

		a:hover svg path {
			fill: #FFFFFF;
		}

		a.yt:hover svg circle {
			fill: #F61C0D;
		}

		a.fb:hover svg circle {
			fill: #2B65F7;
		}

		a.ig:hover svg circle {
			fill: #E60075;
		}

		a.li:hover svg circle {
			fill: #2774B4;
		}
	}

	.pano_controls {
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 80px;
		height: 120px;
		z-index: 2003;

		.zoom_in {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
		}

		.zoom_out {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
		}

		.controls {
			width: 80px;
			height: 80px;
			position: absolute;
			bottom: 0px;
			left: 0px;
		}
	}

	@media (max-width: 968px) {
		min-height: 165px;

		.buttons {
			bottom: 105px;
		}

		.indicators {
			bottom: 60px;
		}

		.social {
			bottom: 10px;
			right: unset;
			width: 100%;
			justify-content: center;
		}
	}

	@media (max-width: 790px) {
		.pano_controls {
			height: 80px;

			.zoom_in,
			.zoom_out {
				display: none;
			}
		}
	}

	@media (max-width: 600px) {
		min-height: 130px;

		.buttons {
			bottom: 85px;

			a {
				padding: 10px;
				font-size: 12px;
  				text-align: center;

				&:first-child {
					margin-left: 10px;
				}
	
				&:last-child {
					margin-right: 10px;
				}
			}
		}

		.indicators {
			bottom: 50px;

			li {
				margin: 0 5px;
			}

			a {
				width: 20px;
  				height: 20px;
			}
		}

		.social {
			a,
			svg {
				width: 30px;
				height: 30px;
			}
		}

		.pano_controls {
			bottom: 10px;
			right: 10px;
			width: 60px;
			height: 60px;

			.controls {
				width: 60px;
				height: 60px;
			}
		}
	}
}
