:root {
  --vh: 90vh;
}

@-ms-viewport {
  width: device-width;
}
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  position: relative;
}

:root {
  --menu-logo-height: 67px;
  --menu-logo-width: 165px;
  --menu-height: 67px;
  --menu-position-top: 20px;
  --menu-position-left: 20px;
  --menu-logo-offset: -82px;
  --menu-burger-offset: -33px;
  --menu-logo-and-burger-offset-compensator: 0 33px 0 82px;
  --menu-lang-padding: 20px;
  --menu-items-as-icons-display: none;
  --menu-items-as-texts-display: inline;
  --menu-item-padding: 0 20px;
  --menu-items-separator-border: 2px solid #989898;
  --menu-reviews-icon-padding: 0 25px;
  --menu-slogan-dektop: inline;
  --menu-slogan-mobile: none;
  --menu-icon-size: 20px;
}
@media (max-width: 680px) {
  :root {
    --menu-logo-height: 50px;
    --menu-logo-width: 123px;
    --menu-height: 50px;
    --menu-position-top: 10px;
    --menu-position-left: 10px;
    --menu-logo-offset: -62px;
    --menu-burger-offset: -25px;
    --menu-logo-and-burger-offset-compensator: 0 25px 0 62px;
    --menu-lang-padding: 13px;
    --menu-items-as-icons-display: block;
    --menu-items-as-texts-display: none;
    --menu-item-padding: 0 10px;
    --menu-items-separator-border: none;
    --menu-reviews-icon-padding: 0 15px;
    --menu-slogan-dektop: none;
    --menu-slogan-mobile: inline;
  }
}

.menu {
  position: absolute;
  top: var(--menu-position-top);
  left: var(--menu-position-left);
  z-index: 101;
  max-width: 650px;
  color: #fff;
  margin-right: 10px;
}
.menu a {
  color: #fff;
  text-decoration: none;
}
.menu a:focus {
  outline: none;
}
.menu-inner {
  padding: var(--menu-logo-and-burger-offset-compensator);
}
.menu-row {
  display: flex;
  align-items: center;
}
.menu-row--top {
  height: var(--menu-height);
  background-color: #484848;
  justify-content: space-between;
}
.menu-row--bottom {
  min-height: 40px;
  background-color: #3d3d3d;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 14px;
}
.menu.turas .menu-row--bottom {
  display: flex;
}
.menu-logo {
  display: flex;
  margin-left: var(--menu-logo-offset);
}
.menu-logo img {
  width: var(--menu-logo-width);
  height: var(--menu-logo-height);
}
.menu-item {
  padding: var(--menu-item-padding);
  white-space: nowrap;
}
.menu-item__email {
  border-left: var(--menu-items-separator-border);
}
.menu-item__fullscreen, .menu-item__gyro {
  display: var(--menu-items-as-icons-display);
}
.menu-item__fullscreen {
  padding: 0 5px 0 10px;
}
.menu-item__gyro {
  padding: 0 10px 0 5px;
}
.menu-item img {
  height: var(--menu-icon-size);
}
.menu-item__phone, .menu-item__email {
  display: var(--menu-items-as-texts-display);
}
.menu-space-eater {
  flex-grow: 1;
}
.menu-right {
  display: flex;
  background-color: #3d3d3d;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.menu-right__languages {
  position: relative;
  width: var(--menu-height);
  height: var(--menu-height);
}
.menu-right__languages-list {
  position: absolute;
  top: 0;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: var(--menu-height);
  min-height: var(--menu-height);
  background-color: #3d3d3d;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.menu-right__languages-list li {
  display: block;
}
.menu-right__languages-list li a {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--menu-lang-padding) 0 0 0;
}
.menu-right__languages-list li:last-child a {
  padding-bottom: var(--menu-lang-padding);
}
.menu-right__languages-list li.hidden {
  display: none;
}
.menu-right__languages-list.open li.hidden {
  display: block;
}
.menu-right__burger {
  display: flex;
  margin-right: var(--menu-burger-offset);
}
.menu-right__burger svg {
  width: var(--menu-height);
  height: var(--menu-height);
}
.menu-right__burger svg .circle-background {
  fill: #262626;
}
.menu-right__burger svg .stripes-color {
  fill: #fff;
}
.menu-title {
  flex-grow: 1;
  padding: 12px 16px 12px 25px;
}
.menu-title .desktop {
  display: var(--menu-slogan-dektop);
}
.menu-title .mobile {
  display: var(--menu-slogan-mobile);
}
.menu-reviews {
  display: flex;
  flex-shrink: 1;
  background-color: #262626;
  border-bottom-right-radius: 20px;
  padding: var(--menu-reviews-icon-padding);
  align-self: stretch;
  align-items: center;
}
.menu-reviews img {
  height: var(--menu-icon-size);
  display: var(--menu-items-as-icons-display);
}
.menu-reviews span {
  display: var(--menu-items-as-texts-display);
}

.cookies + .menu {
  top: 70px;
}

@media (max-width: 460px) {
  .cookies + .menu {
    top: 90px;
  }
}
.cookies {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 50px;
  background-color: #2a2a2a;
  z-index: 104;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cookies span {
  text-align: center;
  padding: 8px 0;
}
.cookies a {
  margin: 0 12px;
}
.cookies a img {
  display: block;
  width: 19px;
  height: 19px;
}

@media (max-width: 460px) {
  .cookies span {
    max-width: 320px;
  }
}
#footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 140px;
  z-index: 102;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
#footer .buttons {
  position: absolute;
  bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}
#footer .buttons a {
  display: block;
  color: #FFF;
  text-decoration: none;
  padding: 20px 30px;
  background-color: rgba(0, 154, 213, 0.85);
}
#footer .buttons a:first-child, #footer .buttons a:last-child {
  background-color: rgba(0, 173, 239, 0.85);
}
#footer .buttons a:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
#footer .buttons a:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
#footer .buttons a:hover {
  background-color: rgba(237, 29, 36, 0.85);
}
#footer .indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
#footer .indicators li {
  margin: 0 10px;
  padding: 0;
}
#footer .indicators a {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3d3d3d;
}
#footer .indicators a.active {
  background-color: #00adef;
}
#footer .indicators a:hover {
  background-color: #ed1d24;
}
#footer .social {
  position: absolute;
  bottom: 20px;
  right: 120px;
  width: 200px;
  display: flex;
}
#footer .social a {
  margin-left: 10px;
  display: block;
  width: 40px;
  height: 40px;
}
#footer .social svg {
  width: 40px;
  height: 40px;
}
#footer .social a:hover svg path {
  fill: #FFFFFF;
}
#footer .social a.yt:hover svg circle {
  fill: #F61C0D;
}
#footer .social a.fb:hover svg circle {
  fill: #2B65F7;
}
#footer .social a.ig:hover svg circle {
  fill: #E60075;
}
#footer .social a.li:hover svg circle {
  fill: #2774B4;
}
#footer .pano_controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 120px;
  z-index: 2003;
}
#footer .pano_controls .zoom_in {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
}
#footer .pano_controls .zoom_out {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
#footer .pano_controls .controls {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
@media (max-width: 968px) {
  #footer {
    min-height: 165px;
  }
  #footer .buttons {
    bottom: 105px;
  }
  #footer .indicators {
    bottom: 60px;
  }
  #footer .social {
    bottom: 10px;
    right: unset;
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 790px) {
  #footer .pano_controls {
    height: 80px;
  }
  #footer .pano_controls .zoom_in,
  #footer .pano_controls .zoom_out {
    display: none;
  }
}
@media (max-width: 600px) {
  #footer {
    min-height: 130px;
  }
  #footer .buttons {
    bottom: 85px;
  }
  #footer .buttons a {
    padding: 10px;
    font-size: 12px;
    text-align: center;
  }
  #footer .buttons a:first-child {
    margin-left: 10px;
  }
  #footer .buttons a:last-child {
    margin-right: 10px;
  }
  #footer .indicators {
    bottom: 50px;
  }
  #footer .indicators li {
    margin: 0 5px;
  }
  #footer .indicators a {
    width: 20px;
    height: 20px;
  }
  #footer .social a,
  #footer .social svg {
    width: 30px;
    height: 30px;
  }
  #footer .pano_controls {
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
  }
  #footer .pano_controls .controls {
    width: 60px;
    height: 60px;
  }
}

.textbox {
  background: #262626;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
}
.textbox .close,
.textbox .back-to-menu {
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
}
.textbox .close img,
.textbox .back-to-menu img {
  width: 45px;
  height: 45px;
}
.textbox .back-to-menu {
  right: 100px;
  z-index: 2;
}
.textbox .menu-container {
  list-style: none;
  margin: 0;
  padding: 0;
}
.textbox .menu-container li {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
.textbox .menu-container a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
}
.textbox .menu-container a:hover {
  text-decoration: underline;
  color: #00adef;
}
.textbox .text-container-outer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}
.textbox .text-container {
  width: 80vw;
  line-height: 1.3;
  margin: auto; /* fit for cut off crap when container align-items: center */
  padding: 70px 0;
}
.textbox .text-container .heading {
  color: #00adef;
  font-size: 20px;
  margin: 15px 0;
}
.textbox .text-container .heading a {
  color: #00adef;
}
.textbox .text-container .sub-heading {
  color: #00adef;
  margin: 15px 0;
}
.textbox ul.long-list {
  column-count: 4;
  padding: 0 0 0 15px;
}
.textbox ul.long-list li {
  margin-right: 30px;
}
.textbox .two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10vw;
}
.textbox .two-columns ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.textbox .two-columns ul li {
  margin-left: 60px;
  position: relative;
}
.textbox .two-columns ul li img {
  position: absolute;
  left: -60px;
  width: 40px;
  height: 40px;
}
.textbox .two-columns .visuals {
  margin-top: 15px;
}
.textbox .two-columns .visuals img {
  max-width: 300px;
  width: 100%;
  display: block;
  margin: 0 auto 30px auto;
}
.textbox .two-columns .visuals img.fullwidth {
  max-width: unset;
}
.textbox .two-columns .visuals iframe {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.textbox .two-columns .visuals .visual-subtitle {
  font-size: 20px;
  margin: 15px;
  text-align: center;
}
.textbox .hero {
  text-align: center;
  font-size: 20px;
}
.textbox .hero p {
  margin: 8px 0;
}
.textbox .hero p a {
  color: #ffffff;
}
.textbox .hero p a:hover {
  text-decoration: underline;
  color: #00adef;
}
.textbox .hero.soc p a {
  text-decoration: none;
}
.textbox .hero.soc p a:hover {
  text-decoration: underline;
}
.textbox .big {
  font-size: 20px;
}
.textbox .projects {
  width: 100%;
  padding: 0;
}
.textbox .list-projects {
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  align-self: flex-start;
}
.textbox .list-projects li {
  position: relative;
}
.textbox .list-projects li .link {
  display: flex;
  align-items: flex-end;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
}
.textbox .list-projects li .link span {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 4vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  text-align: center;
}
.textbox .list-projects li .link:hover span {
  background-color: rgba(237, 29, 36, 0.75);
}
.textbox .list-projects li .maps {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  width: 3vw;
  height: 3vw;
}

.contact-form input {
  display: block;
  margin: 15px 0;
  padding: 8px;
  width: 100%;
  max-width: 280px;
  outline: none;
}
.contact-form input::placeholder {
  opacity: 1; /* Firefox */
}
.contact-form input.error {
  background-color: #ed1d24;
  border-color: #ed1d24;
  color: #ffffff;
}
.contact-form input.error::placeholder {
  color: #ffffff;
}
.contact-form input:focus, .contact-form input:hover, .contact-form input:active {
  outline: none;
}
.contact-form label.error {
  color: #ed1d24;
}
.contact-form .contact-form-errors,
.contact-form .contact-form-fatal {
  color: #ed1d24;
  margin: 12px 0 4px;
}
.contact-form .contact-form-success {
  color: #29c200;
  margin: 12px 0 4px;
}

a.button {
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  padding: 12px 23px;
  margin: 15px 20px 0 0;
  background-color: #00adef;
  border-radius: 23px;
}
a.button:hover {
  background-color: #ed1d24;
}

a.button.external-menu-link {
  margin-top: 6px;
}

p {
  margin: 15px 0;
}

.no-underline {
  text-decoration: none;
}

@media (max-width: 1440px) {
  .textbox .list-projects {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .textbox .list-projects li .link span {
    height: 7vw;
  }
  .textbox .list-projects li .maps {
    width: 4vw;
    height: 4vw;
  }
}
@media (max-width: 1024px) {
  .textbox ul.long-list {
    column-count: 3;
  }
  .textbox .two-columns {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
  .textbox .two-columns .visuals {
    margin-top: 0;
    text-align: center;
  }
  .textbox .list-projects {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .textbox .list-projects li .link span {
    height: 10vw;
  }
  .textbox .list-projects li .maps {
    width: 5vw;
    height: 5vw;
  }
}
@media (max-width: 768px) {
  .textbox ul.long-list {
    column-count: 2;
  }
  .textbox .list-projects {
    grid-template-columns: 1fr 1fr;
  }
  .textbox .list-projects li .link span {
    height: 14vw;
  }
  .textbox .list-projects li .maps {
    width: 7vw;
    height: 7vw;
  }
}
@media (max-width: 468px) {
  .textbox .text-container:not(.projects) {
    width: 100%;
    padding: 70px 20px;
  }
  .textbox ul.long-list {
    column-count: 1;
  }
  .textbox .list-projects {
    grid-template-columns: 1fr;
  }
  .textbox .list-projects li .link span {
    height: 22vw;
  }
  .textbox .list-projects li .maps {
    width: 11vw;
    height: 11vw;
  }
}
@media (max-width: 360px) {
  .contact-form input {
    max-width: 259px;
  }
  .contact-form .g-recaptcha > div {
    width: 100% !important;
  }
  .contact-form .g-recaptcha > div iframe {
    width: 100% !important;
  }
}