:root {
    --vh: 90vh;
}

$font-base: Arial, Helvetica, sans-serif;

$font-size--small: 12px;
$font-size--smaler: 14px;
$font-size--base: 16px;
$font-size--bigger: 18px;
$font-size--bigg: 20px;

$color-menu-text: #fff;
$color-menu-top-row-separator: #989898;
$color-menu-top-row-left: #484848;
$color-menu-top-row-right: #3d3d3d;
$color-menu-bottom-row-left: #3d3d3d;
$color-menu-bottom-row-right: #262626;
$color-menu-burger-background: #262626;
$color-menu-burger-stripes: #fff;
