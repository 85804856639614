.textbox {
	background: #262626;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 200;

	.close,
	.back-to-menu {
		display: flex;
		width: 45px;
		height: 45px;
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: 1;

		img {
			width: 45px;
			height: 45px;
		}
	}

	.back-to-menu {
		right: 100px;
		z-index: 2;
	}

	.menu-container {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			justify-content: center;
			margin: 15px 0;
		}

		a {
			display: block;
			color: #ffffff;
			text-decoration: none;
			font-size: 20px;

			&:hover {
				text-decoration: underline;
				color: #00adef;
			}
		}
	}

	.text-container-outer {
		width: 100%;
		height: 100%;
		display: flex;
		//justify-content: center;
		//align-items: center;
		overflow-y: auto;
	}

	.text-container {
		width: 80vw;
		line-height: 1.3;
		margin: auto; /* fit for cut off crap when container align-items: center */
		padding: 70px 0;

		.heading {
			color: #00adef;
			font-size: 20px;
			margin: 15px 0;

			a {
				color: #00adef;
			}
		}

		.sub-heading {
			color: #00adef;
			margin: 15px 0;
		}
	}

	ul.long-list {
		column-count: 4;
		padding: 0 0 0 15px;

		li {
			margin-right: 30px;
		}
	}

	.two-columns {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 10vw;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin-left: 60px;
				position: relative;

				img {
					position: absolute;
					left: -60px;
					width: 40px;
					height: 40px;
				}
			}
		}

		.visuals {
			margin-top: 15px;

			img {
				max-width: 300px;
				width: 100%;
				display: block;
				margin: 0 auto 30px auto;

				&.fullwidth {
					max-width: unset;
				}
			}

			iframe {
				display: block;
				width: 100%;
				height: auto;
				aspect-ratio: 16/9;
			}

			.visual-subtitle {
				font-size: 20px;
				margin: 15px;
				text-align: center;
			}
		}
	}

	.hero {
		text-align: center;
		font-size: 20px;

		p {
			margin: 8px 0;

			a {
				color: #ffffff;

				&:hover {
					text-decoration: underline;
					color: #00adef;
				}
			}
		}

		&.soc p a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.big {
		font-size: 20px;
	}

	.projects {
		width: 100%;
		padding: 0;
	}

	.list-projects {
		display: grid;
		list-style: none;
		margin: 0;
		padding: 0;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 100%;
  		align-self: flex-start;

		li {
			position: relative;

			.link {
				display: flex;
				align-items: flex-end;
				aspect-ratio: 1/1;
				background-repeat: no-repeat;
				background-size: cover;
				text-decoration: none;

				span {
					background-color: rgba(0, 0, 0, 0.75);
					width: 100%;
					height: 4vw;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 32px;
  					text-align: center;
				}

				&:hover span {
					background-color: rgba(237, 29, 36, 0.75);
				}
			}

			.maps {
				position: absolute;
				top: 20px;
				left: 20px;
				display: flex;
				width: 3vw;
  				height: 3vw;
			}
		}
	}
}

.contact-form {
	input {
		display: block;
		margin: 15px 0;
		padding: 8px;
		width: 100%;
		max-width: 280px;
		outline: none;

		&::placeholder {
			opacity: 1; /* Firefox */
		}

		&.error {
			background-color: #ed1d24;
			border-color: #ed1d24;
			color: #ffffff;

			&::placeholder {
				color: #ffffff;
			}
		}

		&:focus,
		&:hover,
		&:active {
			outline: none;
		}
	}

	label.error {
		color: #ed1d24;
	}

	.contact-form-errors,
	.contact-form-fatal {
		color: #ed1d24;
		margin: 12px 0 4px;
	}

	.contact-form-success {
		color: #29c200;
		margin: 12px 0 4px;
	}
}

a.button {
	display: inline-block;
	color: #FFF;
	text-decoration: none;
	padding: 12px 23px;
	margin: 15px 20px 0 0;
	background-color: #00adef;
	border-radius: 23px;

	&:hover {
		background-color: #ed1d24;
	}
}

a.button.external-menu-link {
	margin-top: 6px;
}

p {
	margin: 15px 0;
}

.no-underline {
	text-decoration: none;
}

@media (max-width: 1440px) {
	.textbox {
		.list-projects {
			grid-template-columns: 1fr 1fr 1fr 1fr;

			li {
				.link span {
					height: 7vw;
				}

				.maps {
					width: 4vw;
  					height: 4vw;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.textbox {
		ul.long-list {
			column-count: 3;
		}

		.two-columns {
			grid-template-columns: 1fr;
			row-gap: 8px;

			.visuals {
				margin-top: 0;
				text-align: center;
			}
		}

		.list-projects {
			grid-template-columns: 1fr 1fr 1fr;

			li {
				.link span {
					height: 10vw;
				}

				.maps {
					width: 5vw;
  					height: 5vw;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.textbox {
		ul.long-list {
			column-count: 2;
		}

		.list-projects {
			grid-template-columns: 1fr 1fr;

			li {
				.link span {
					height: 14vw;
				}

				.maps {
					width: 7vw;
  					height: 7vw;
				}
			}
		}
	}
}

@media (max-width: 468px) {
	.textbox {
		.text-container:not(.projects) {
			width: 100%;
			padding: 70px 20px;
		}

		ul.long-list {
			column-count: 1;
		}

		.list-projects {
			grid-template-columns: 1fr;

			li {
				.link span {
					height: 22vw;
				}

				.maps {
					width: 11vw;
  					height: 11vw;
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.contact-form {
		input {
			max-width: 259px;
		}

		.g-recaptcha > div {
			width: 100% !important;

			iframe {
				width: 100% !important;
			}
		}
	}
}
